<template>    
    <div style="background-color: #f2f7ff">
        <Sidebar></Sidebar>

        <div id="main">
            <header class="mb-3">
                <a href="#" class="burger-btn d-block d-xl-none">
                    <i class="bi bi-justify fs-3"></i>
                </a>
            </header>

            <div class="page-heading">
                <h3>Statistics</h3>
            </div>

            <div class="page-content">
                <section class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="card">
                                    <div class="card-body px-3 py-4-5">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="stats-icon purple">
                                                    <i class="iconly-boldFilter"></i>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <h6 class="text-muted font-semibold">Orders</h6>
                                                <h6 class="font-extrabold mb-0">{{ statistics.total_order }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="card">
                                    <div class="card-body px-3 py-4-5">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="stats-icon blue">
                                                    <i class="iconly-boldCategory"></i>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <h6 class="text-muted font-semibold">Product</h6>
                                                <h6 class="font-extrabold mb-0">{{ statistics.total_product }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body py-4 px-5">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-xl">
                                        <img :src="me.profile_url" alt="Face 1" style="object-fit: cover">
                                    </div>
                                    <div class="ms-3 name">
                                        <h5 class="font-bold m-0">{{ me.name }}</h5>
                                        <a @click.prevent="logout" href="" class="text-muted mb-0">Logout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer>
                <div class="footer clearfix mb-0 text-muted">
                    <div class="float-start">
                        <p>2021 &copy; Rinyuakng</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios'

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            token: localStorage.getItem('token'),
            me: '',
            statistics: {
                total_order: '',
                total_product: ''
            }
        }
    },
    methods: {
        getMe() {
            axios.get(`auth/me?token=${this.token}`)
                .then(res => {
                    this.me = res.data;
                })
                .catch(err => {
                    alert(err.response.data.message)
                });
        },
        getStatistics() {
            axios.get(`mitra/my-order?token=${this.token}`)
                .then(res => {
                    this.statistics.total_order = res.data.length;
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 

            axios.get(`mitra/my-product?token=${this.token}`)
                .then(res => {
                    this.statistics.total_product = res.data.length;
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        },
        logout() {
            axios.get(`auth/logout?token=${this.token}`)
				.then(res => {
					localStorage.removeItem('token');
					localStorage.removeItem('role');

					this.$router.push('/');
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
        }
    },
    created() {
        this.getMe();
        this.getStatistics();
    }
}
</script>